import { render, staticRenderFns } from "./LanguageSwitcher.vue?vue&type=template&id=1729583d&"
import script from "./LanguageSwitcher.vue?vue&type=script&lang=js&"
export * from "./LanguageSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./LanguageSwitcher.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VIcon,VImg,VList,VListItem,VMenu,VTextField})
